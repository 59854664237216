import { SET_ACTIVE_PANELS, SET_LEAD_TOKEN, TOGGLE_EXPANDED_PANELS, UPDATE_LOCATION, SET_PRIVACY, SET_PAGE_BACKGROUND_OPTION } from './actions'

export const INITIAL_STATE = { 
  isExpanded: false, 
  activePanels: [],
  location: {},
  privacyAccepted: false,
  pageBackground: "white",
  leadToken: null
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_ACTIVE_PANELS:
      return {
        ...state,
        activePanels: action.payload
      }
    case TOGGLE_EXPANDED_PANELS:
      return {
        ...state,
        isExpanded: !state.isExpanded
      }
    case UPDATE_LOCATION:
      return {
        ...state,
        location: action.payload
      }
    case SET_PRIVACY:
      return {
        ...state,
        privacyAccepted: action.payload
      }
    case SET_PAGE_BACKGROUND_OPTION:
      return {
        ...state,
        pageBackground: action.payload
      }
    case SET_LEAD_TOKEN:
      return {
        ...state,
        leadToken: action.payload
      }
    default:
      return state
  }
}