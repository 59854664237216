export const SET_ACTIVE_PANELS = 'options/SET_ACTIVE_PANELS'
export const SET_LEAD_TOKEN = 'options/SET_LEAD_TOKEN'
export const TOGGLE_EXPANDED_PANELS = 'options/TOGGLE_EXPANDED_PANELS'
export const UPDATE_LOCATION = 'options/UPDATE_LOCATION'
export const SET_PRIVACY = 'options/SET_PRIVACY'
export const SET_PAGE_BACKGROUND_OPTION = 'options/SET_PAGE_BACKGROUND_OPTION'

export const set = (payload) => ({
  type: SET_ACTIVE_PANELS,
  payload: payload
})

export const toggle = () => ({
  type: TOGGLE_EXPANDED_PANELS
})

export const updateLocation = (payload) => ({
  type: UPDATE_LOCATION,
  payload: payload
})

export const setPrivacy = (payload) => ({
  type: SET_PRIVACY,
  payload: payload
})

export const setPageBackgroundOption = (payload) => ({
  type: SET_PAGE_BACKGROUND_OPTION,
  payload: payload
})

export const setLeadTokenOption = (payload) => ({
  type: SET_LEAD_TOKEN,
  payload: payload
})